
// desenvolvimento
 
export const env = {
    REACT_APP_BASEURL: "https://tecnolab.adilsonmoraesdasilva.com.br/server",
    REACT_APP_SERVER_USR: "root",
    REACT_APP_SERVER_PASS: "123",

    REACT_APP_SITE_KEY: "6LeB-oQpAAAAAPEBjdp4WCdfxBlO2AL7IsXhbHBX"
}  
 
// produção
/*
export const env = {
    REACT_APP_BASEURL: "http://localhost/server",
    REACT_APP_SERVER_USR: "root",
    REACT_APP_SERVER_PASS: "SA#124uyt53@31",

    REACT_APP_SITE_KEY: "6LeB-oQpAAAAAPEBjdp4WCdfxBlO2AL7IsXhbHBX"
}
*/